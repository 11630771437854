<template>
  <div class="father1">
    <div class="right-aside" ref="todom">
      <div class="div1">
        <div class="img1">
          <div class="img2">
            <img class="imgself" :src="imgBaseUrl + infoList.photo" alt="" />
          </div>
        </div>

        <div class="info" v-if="!isClass">
          <div class="info1">
            <ul>
              <li><span>姓名</span></li>
              <li><span>院系</span></li>
              <li><span>班级</span></li>
              <li><span>学号</span></li>
            </ul>
          </div>
          <div class="info2">
            <ul>
              <li>
                <span>{{ infoList.name }}</span>
              </li>
              <li>
                <span>{{ infoList.dname }}</span>
              </li>
              <li>
                <span>{{ infoList.cname }}</span>
              </li>
              <li>
                <span>{{ infoList.studentnum }}</span>
              </li>
            </ul>
          </div>
          <div class="info3">
            <img class="imga" :src="imgUrlWx" alt="" />
          </div>
        </div>

        <div class="info" v-else>
          <div class="info1">
            <ul>
              <li><span>院系</span></li>
              <li><span>班级</span></li>
              <li><span>班号</span></li>
            </ul>
          </div>
          <div class="info2">
            <ul>
              <li>
                <span>{{ infoList.depName }}</span>
              </li>
              <li>
                <span>{{ infoList.className }}</span>
              </li>
              <li>
                <span>{{ infoList.classNum }}</span>
              </li>
            </ul>
          </div>
          <div class="info3">
            <img class="imga" :src="imgUrlWx" alt="" />
          </div>
        </div>
      </div>
      <div class="div2">
        <div class="university">
          <span>{{ infoList.school }}</span>
        </div>
        <div class="type">{{ isClass ? "班级卡" : "学生卡" }}</div>
      </div>
    </div>
    <div class="mgt16 pdb16 align-center">
      <el-button type="primary" @click="download">下载</el-button>
    </div>
  </div>
</template>

<script src="https://cdn.bootcss.com/html2canvas/0.5.0-beta4/html2canvas.js"></script>
<script src="https://cdn.bootcss.com/jspdf/1.3.4/jspdf.debug.js"></script>
<script>
import html2canvas from "html2canvas";
import { getHttpUrl } from "@/utils/request";
export default {
  props: ["cardData", "isClass"],
  components: {},
  data() {
    return {
      imgBaseUrl: getHttpUrl(),
      infoList: [],
      imgUrlWx: "",
    };
  },
  created() {
    this.listall();
  },
  methods: {
    download() {
      html2canvas(this.$refs.todom, {
        backgroundColor: "#ffffff",
        useCORS: true,
      }).then((canvas) => {
        var imgData = canvas.toDataURL("image/jpeg");
        this.fileDownload(imgData);
      });
    },
    fileDownload(downloadUrl) {
      let aLink = document.createElement("a");
      aLink.style.display = "none";
      aLink.href = downloadUrl;
      if (this.isClass) {
        aLink.download = this.infoList.className + "的班级卡.png";
      } else {
        aLink.download = this.infoList.name + "的学生卡.png";
      }
      // 触发点击-然后移除
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
    },
    addInfo() {
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
    },
    // 获取
    listall() {
      this.infoList = this.cardData;
      this.imgUrlWx = this.isClass
        ? `${this.imgBaseUrl}${this.infoList.wxCode}`
        : `${this.imgBaseUrl}${this.infoList.wxcode}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.right-aside {
  width: 595px;
  height: 346px;
  border-radius: 30px;
  margin: 0 auto;
  overflow: hidden;
}
.father1 {
  min-width: 595px;
  border-radius: 30px;
}
.div1 {
  width: 100%;
  height: 291px;
  background-color: #eaedff;
  position: relative;
}
.div2 {
  width: 100%;
  height: 55.5px;
  background-color: #3951fb;
}
.img1 {
  width: 130px;
  height: 180px;
  background-color: white;
  position: absolute;
  left: 40px;
  top: 60px;
  border-radius: 10px;
}
.img2 {
  width: 118px;
  height: 167px;
  left: 6px;
  top: 6px;
  position: absolute;
}
.imgself {
  width: 118px;
  height: 167px;
}
.info {
  position: absolute;
  width: 265px;
  height: 168px;
  left: 190px;
  top: 66px;
}
.info1 {
  width: 48px;
  height: 168px;
  left: 0px;
  top: 0px;
  position: absolute;
  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    li {
      width: 100%;
      height: 42px;
      line-height: 42px;
      font-size: 20px;
    }
  }
}
.info2 {
  width: 187px;
  height: 168px;
  left: 56px;
  top: 0px;
  position: absolute;
  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    li {
      width: 100%;
      height: 40px;
      line-height: 42px;
      font-size: 16px;
      border-bottom: 2px solid black;
    }
  }
}
.info3 {
  width: 101px;
  height: 101px;
  position: absolute;
  left: 285px;
  top: 34px;
}
.imga {
  width: 101px;
  height: 101px;
}
.div2 {
  width: 100%;
  position: relative;
}
.university {
  position: absolute;
  display: inline-block;
  color: white;
  font-size: 22px;
  height: 100%;
  left: 10%;
  top: 17%;
}
.type {
  position: absolute;
  display: inline-block;
  color: white;
  font-size: 22px;
  height: 100%;
  right: 10%;
  top: 17%;
}
</style>